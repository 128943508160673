.Mainapp {
background-color: #fff;
}

.banner{
  width: 100%;
  height: 781px;text-align: end;
  position: unset;
  background-size: cover;
  background-image: url("./imgs/banner.jpeg");
}

.logo{
  width: 355px;
  height: 77px;
  margin-top: 39px;

}
.Bluebg{
  margin-top: -16px;
  background-color:#2d3a8e;
}

.LoanFast{

  text-align: center;
  color: white;
  font-size: 90px;
  margin-top: 15px;
  font-family: 'Ubuntu', sans-serif;
}
.weekend{
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  color: white;
  font-size: 35px;
  margin-top: 5px;
}
.OFFERS{
  font-family: 'Ubuntu', sans-serif;
  font-weight: bolder;
  text-align: center;
  color: white;
  font-size: 60px;
  margin-top: 5px;
}
.pcss{
  padding-left: 10px;
  color: white;
  font-weight: 500;
  font-size: 19px;
}
.litextcolor{
  font-size: 35px;
  font-weight:bolder;
  color: white;
}
ul{
  list-style:numb;
}
.containers{
padding: 20px;
}
.pcssnormal{
  color: #fff;
}
.contacts{
  text-align: center;
}
.contactbtn{
  height: 40px;
  width: 200px;
  color:black;
  margin-bottom: 50px;
}
.centercss{
  text-align: center;
}
.sendbtn{
  height: 40px;
  width: 201px;
  text-align: center;
  color:white;
  background-color: rgb(8, 8, 107);
  margin-bottom: 50px;
}

.homebtn{
  height: 40px;
  width: 200px;
  text-align: center;
  color:white;
  background-color: rgb(8, 8, 107);
  margin-bottom: 50px;
}
.contacth2{
  font-size: 30px;
  color: white;
  text-align: center;
}
.homecontact{margin-top: 50px;
  font-size: 30px;
  color: white;
  text-align: center;
}
.bluebg{
  height: 60px;
  background-color: rgb(8, 8, 107);
}
.border{
  margin-top: 50px;
}

.textheight{
  height: 200px;
}

